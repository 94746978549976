.cr-report-button {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.cr-table-wrapper {
  width: 100%;
  max-width: 100%;
  max-height: 1200px;
  overflow: auto;
}

.cr-view {
  margin: auto;
}

.cr-table-container {
  overflow-y: scroll;
}

.cr-comp-read-table {
  width: auto !important;
  margin-top: 0.5em;
  border: 1px solid #eee;
  table-layout: fixed;
}

.cr-col-name {
}

.cr-jobrun-table-wrapper {
  width: 205px;
  vertical-align: top !important;
}

.cr-triage-panel-element {
  margin-top: 30px;
}

.cr-triage-run-table {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: grey;
}
