.test-dialog {
  padding: 20px;
}

.job-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;

  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  word-wrap: break-word;
}

.job-failure-card {
  padding: 20px;
}

.job-divider {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.job-button {
  margin-right: 10px !important;
}

.header {
  margin-bottom: 20px !important;
}
