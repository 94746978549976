.test-failure-card {
  padding: 20px;
}

.test-divider {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.test-button {
  margin-right: 10px !important;
}

.header {
  margin-bottom: 20px !important;
}
