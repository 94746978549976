.wrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
}

.view {
  margin: auto;
  width: '100%';
}

.table-container {
  overflow-y: scroll;
  width: '100%';
  max-width: '100%';
}

.test-variant-table {
  margin-top: 0.5em;
  border: none;
  table-layout: fixed;
}

.col-name {
  width: 600px;
  min-width: 600px;
  max-width: 600px;
}

.cell-name {
  display: block;
  line-height: normal;
  white-space: break-spaces;
  word-wrap: break-word;
}

.col-result {
  opacity: 1;
  border-left: 1px solid black;
  border-right: 1px solid black;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100vh;
  min-width: 100px;
  max-width: 170px;
}

.col-result-full {
  opacity: 1;
  border-left: 1px solid black;
  border-right: 1px solid black;
  width: 175px;
  min-width: 175px;
  max-width: 175px;
}

.cell-result {
  border: 3px solid black;
  text-align: center;
  z-index: 9999;
}
