.checkboxlist-label {
  color: white;
  font-weight: bold;
  background-color: rgb(0, 153, 255);
  padding: 5px;
  margin-bottom: 10px;
}
.checkboxlist-headerName {
  width: 220px;
  padding: 0px;
  margin: 0px;
}
.checkboxlist-summary {
  background-color: rgb(0, 153, 255);
  margin: 0px !important;
  padding: 0px;
}
